<template>
  <div>
    <!-- section (문의하기) -->
    <div class="d-flex justify-center" style="background-color: #F9FAFC; min-height: calc(100vh - 64px - 224px)">
      <!-- 웹 (1920*1080 이상) -->
      <div class="d-none d-xl-flex flex-column justify-center" style="width: 40%; padding: 60px 0px 60px 0px">
        <div>
          <div style="font-size: 48px; font-weight: 700" class="text-center pb-8">
            지금 상담을 요청해보세요!
          </div>
          <div style="font-size: 24px; color: #424242" class="text-center pb-2">
            아래 내용을 복사하여 contact@cogcom.kr로 메일을 보내주세요.
          </div>
          <div style="font-size: 18px; color: #616161" class="text-center pb-6">
            *서식 복사 버튼으로 내용을 복사하여 편하게 작성하세요.
          </div>
          <v-card>
            <div class="px-12 py-4 text-right">
              <v-btn color="#367BF5" dark large class="px-6" @click="copyTemplate()">
                서식 복사
              </v-btn>
            </div>
            <v-divider></v-divider>
            <div class="px-12 pt-8 pb-12">
              <div style="font-size: 24px" class="pb-2">
                1. 문의 종류
              </div>
              <div style="font-size: 18px; color: #757575" class="pb-8">
                예시) 서비스 안내/기술 상담/AI바우처/R&D과제/데이터 문의/상담/기타
              </div>
              <div style="font-size: 24px" class="pb-2">
                2. 문의 내용
              </div>
              <div style="font-size: 18px; color: #757575">
                가능한 솔루션, 견적 관련 문의 예시: "서비스에 활용할 텍스트 분석 솔루션 구축이 필요합니다. 예산은 이 정도 입니다."
              </div>
              <div style="font-size: 18px; color: #757575">
                연구개발 문의 예시: "정부 R&D 과제를 진행 중입니다. O년짜리 과제이고 현재 (연구기획, 제안서 작성, ~년차, 마무리) 단계 진행 중 입니다. (~기능 연구개발, 공인시험성적서, 보고서 작성) 도움이 필요합니다."
              </div>
            </div>
          </v-card>
        </div>
      </div>
      <!-- 웹 (1920*1080 미만) -->
      <div class="d-none d-xl-none d-sm-flex flex-column justify-center" style="width: 60%; padding: 60px 0px 60px 0px">
        <div>
          <div style="font-size: 48px; font-weight: 700" class="text-center pb-4">
            지금 상담을 요청해보세요!
          </div>
          <div style="font-size: 24px; color: #424242" class="text-center pb-2">
            아래 내용을 복사하여 contact@cogcom.kr로 메일을 보내주세요.
          </div>
          <div style="font-size: 18px; color: #616161" class="text-center pb-6">
            *서식 복사 버튼으로 내용을 복사하여 편하게 작성하세요.
          </div>
          <v-card>
            <div class="px-12 py-4 text-right">
              <v-btn color="#367BF5" large dark class="px-6" @click="copyTemplate()">
                서식 복사
              </v-btn>
            </div>
            <v-divider></v-divider>
            <div class="px-12 pt-8 pb-12">
              <div style="font-size: 24px" class="pb-2">
                1. 문의 종류
              </div>
              <div style="font-size: 18px; color: #757575" class="pb-8">
                예시) 서비스 안내/기술 상담/AI바우처/R&D과제/데이터 문의/상담/기타
              </div>
              <div style="font-size: 24px" class="pb-2">
                2. 문의 내용
              </div>
              <div style="font-size: 18px; color: #757575">
                가능한 솔루션, 견적 관련 문의 예시: "서비스에 활용할 텍스트 분석 솔루션 구축이 필요합니다. 예산은 이 정도 입니다."
              </div>
              <div style="font-size: 18px; color: #757575">
                연구개발 문의 예시: "정부 R&D 과제를 진행 중입니다. O년짜리 과제이고 현재 (연구기획, 제안서 작성, ~년차, 마무리) 단계 진행 중 입니다. (~기능 연구개발, 공인시험성적서, 보고서 작성) 도움이 필요합니다."
              </div>
            </div>
          </v-card>
        </div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-flex align-center main-sections" style="width: 90%; padding: 30px 0px 60px 0px">
        <div>
          <div style="font-size: 24px; font-weight: 700" class="text-center pb-4">
            지금 상담을 요청해보세요!
          </div>
          <div style="font-size: 18px; color: #424242" class="text-center pb-2">
            아래 내용을 복사하여 contact@cogcom.kr로 메일을 보내주세요.
          </div>
          <div style="font-size: 14px; color: #616161" class="text-center pb-6">
            *서식 복사 버튼으로 내용을 복사하여 편하게 작성하세요.
          </div>
          <v-card>
            <div class="px-4 py-3 text-right">
              <v-btn color="#367BF5" small dark class="px-4" @click="copyTemplate()">
                서식 복사
              </v-btn>
            </div>
            <v-divider></v-divider>
            <div class="pl-6 pr-4 pt-4 pb-6">
              <div style="font-size: 18px" class="pb-2">
                1. 문의 종류
              </div>
              <div style="font-size: 12px; color: #757575" class="pb-8">
                예시) 서비스 안내/기술 상담/AI바우처/R&D과제/데이터 문의/상담/기타
              </div>
              <div style="font-size: 18px" class="pb-2">
                2. 문의 내용
              </div>
              <div style="font-size: 12px; color: #757575">
                가능한 솔루션, 견적 관련 문의 예시: "서비스에 활용할 텍스트 분석 솔루션 구축이 필요합니다. 예산은 이 정도 입니다."
              </div>
              <div style="font-size: 12px; color: #757575">
                연구개발 문의 예시: "정부 R&D 과제를 진행 중입니다. O년짜리 과제이고 현재 (연구기획, 제안서 작성, ~년차, 마무리) 단계 진행 중 입니다. (~기능 연구개발, 공인시험성적서, 보고서 작성) 도움이 필요합니다."
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>

    <v-snackbar class="d-none d-xl-flex" v-model="showAlert" color="success" style="height: 80vh; top: 0">
      <div class="d-flex align-center">
        <v-icon>mdi-checkbox-marked-circle</v-icon>
        <div style="font-size: 16px" class="ml-2">
          서식 복사가 완료되었습니다.
        </div>
      </div>
    </v-snackbar>
    <v-snackbar class="d-none d-xl-none d-sm-flex" v-model="showAlert" color="success" style="height: 90vh; top: 0">
      <div class="d-flex align-center">
        <v-icon>mdi-checkbox-marked-circle</v-icon>
        <div style="font-size: 16px" class="ml-2">
          서식 복사가 완료되었습니다.
        </div>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAlert: false,
    }
  },

  methods: {
    clickContactUs() {
      window.scroll({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
    },
    goAiVoucher() {
      this.$router.push('/ai-voucher').catch(() => {})
      scrollTo(0, 0)
    },
    goSolution() {
      this.$router.push('/solution').catch(() => {})
      scrollTo(0, 0)
    },
    copyTemplate() {
      const t = document.createElement("textarea")
      document.body.appendChild(t)
      t.value = '1. 문의 종류\n예시) 서비스 안내/기술 상담/AI바우처/R&D과제/데이터 문의/상담/기타\n\n2. 문의 내용\n가능한 솔루션, 견적 관련 문의 예시: "서비스에 활용할 텍스트 분석 솔루션 구축이 필요합니다. 예산은 이 정도 입니다."\n연구개발 문의 예시: "정부 R&D 과제를 진행 중입니다. O년짜리 과제이고 현재 (연구기획, 제안서 작성, ~년차, 마무리) 단계 진행 중 입니다. (~기능 연구개발, 공인시험성적서, 보고서 작성) 도움이 필요합니다."'
      t.select()
      document.execCommand('copy')
      document.body.removeChild(t)
      this.showAlert = true
      setTimeout(() => {
        // this.showAlert = false
      }, 3000)
    }
  }
}
</script>

<style>

</style>
